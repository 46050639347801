$(document).ready(function() { // Ждём загрузки страницы
	// бургер меню
	var btn = $('.burger-menu__btn');

	btn.on('click', function() {
		$(this).toggleClass('active');
		$(this).toggleClass('not-active');
	});
	$('.burger-menu').click(function () {
		$('.navbar-bottom').slideToggle(500);
	});
	$(window).resize(function () {
		if ($(window).width() > 994) {
			$('.navbar-bottom').removeAttr('style');
		}
	});

	var button = $('#button-up');
	$(window).scroll (function () {
		if ($(this).scrollTop () > 300) {
			button.fadeIn();
		} else {
			button.fadeOut();
		}
	});
	button.on('click', function(){
		$('body, html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

	$("#menu").on("click","a", function (event) {
		event.preventDefault();
		var id  = $(this).attr('href'),
				top = $(id).offset().top;
		$('body,html').animate({scrollTop: top}, 1000);
	});

	window.openWin = function (state) {
		document.getElementById('modal-call').style.display = state;
		document.getElementById('modal-call-two').style.display = state;
		document.getElementById('modal-filter').style.display = state;
	};
	window.toSend = function (state) {
		var name = document.getElementById('name').value;
		var phone = document.getElementById('phoneNumber').value;
		if (name && phone.length > 17) {
			document.getElementById('modal-call').style.display = state;
			jivo_api.setCustomData([{"title": name, "content": phoneNumber}]);
		}
	};

	$(".image").click(function(){	// Событие клика на маленькое изображение
		var img = $(this);	// Получаем изображение, на которое кликнули
		var src = img.attr('src'); // Достаем из этого изображения путь до картинки
		$("body").append("<div class='popup'>"+ //Добавляем в тело документа разметку всплывающего окна
				"<div class='popup_bg'></div>"+ // Блок, который будет служить фоном затемненным
				"<img src='"+src+"' class='popup_img' />"+ // Само увеличенное фото
				"</div>");
		$(".popup").fadeIn(200); // Медленно выводим изображение
		$(".popup_bg").click(function(){	// Событие клика на затемненный фон
			$(".popup").fadeOut(200);	// Медленно убираем всплывающее окн
			setTimeout(function() {	// Выставляем таймер
				$(".popup").remove(); // Удаляем разметку всплывающего окна
			}, 200);
		});
	});

});